<template>
  <layout page="responsible-student">
    <page-title icon="lni lni-users" :breadcrumb="breadcrumb">Alunos</page-title>
    <card-list>
      <card-list-header title="Alunos" />
      <div class="card-content">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(student) in students"
              :key="student.id"
            >
              <td>{{ student.name }}</td>
              <td>{{ student.email }}</td>
              <td>
                <button class="button is-primary is-small" @click="forceLogin(student.id)">Acessar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card-list>
  </layout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/api'
import SwalDelete from '@/helpers/SwalDelete'
import userRules from '@/config/userRules'
import { DASHBOARD, STUDENT_LIST } from '@/config/breadcrumb/responsible'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardList from '@/components/CardList'
import CardListHeader from '@/components/CardList/Header'

export default {
  name: 'ResponsibleStudents',
  components: {
    Layout,
    PageTitle,
    CardList,
    CardListHeader
  },
  created () {
    api.get(`/responsibles/${this.user.id}/students`).then(res => {
      this.students = res.data
    })
  },
  data () {
    return {
      students: []
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        DASHBOARD(),
        STUDENT_LIST(true)
      ]
    },
    userRules () {
      return userRules
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    forceLogin (idStudent) {
      SwalDelete.fire({
        html: 'Se fizer o login com este aluno, esta sessão atual é encerrada',
        confirmButtonText: 'Sim!'
      }).then(ok => {
        if (ok.isConfirmed) {
          api.get(`/students/${idStudent}`).then(res => {
            this.login({
              ...res.data,
              rule: this.userRules.STUDENT
            })

            this.$router.push({
              path: '/p/s',
              meta: {
                loginRedirect: true
              }
            })
          })
        }
      })
    }
  }
}
</script>
