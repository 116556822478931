const DASHBOARD = (isActive = false) => {
  return {
    path: '/p/r',
    text: 'Dashboard',
    isActive
  }
}

const STUDENT_LIST = (isActive = false) => {
  return {
    path: '/p/r/alunos',
    text: 'Alunos',
    isActive
  }
}

export {
  DASHBOARD,
  STUDENT_LIST
}
